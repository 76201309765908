<template>
  <div id="example">
    <l-map
      ref="map"
      :zoom="zoom"
      :center="center"
      style="height: 300px; width: 100%"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-gpx :gpx-file="gpx" :visible="gpxVisible" @gpx-loaded="onGpxLoaded" />
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer } from 'vue2-leaflet'
import LGpx from './LGpx.vue'

export default {
  props: ['gpx'],
  components: {
    LMap,
    LTileLayer,
    LGpx
  },
  methods: {
    async onGpxLoaded(loadedEvent) {
      const { mapObject } = this.$refs.map
      let gpxMapObject = loadedEvent.target
      mapObject.fitBounds(gpxMapObject.getBounds())
    },

    onClickButton() {
      this.gpxVisible = !this.gpxVisible
    }
  },
  data: () => ({
    gpxVisible: true,
    zoom: 5,
    center: [0, 0],
    url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="http://www.heber.com.br">Heber Almeida</a> contributors'
  })
}
</script>

<style lang="scss">
@import '~leaflet/dist/leaflet.css';

html,
body,
#example {
  height: 100%;
  margin: 0;
  .leaflet-marker-icon {
    height: 35px !important;
    margin-top: -36px !important;
  }
}
</style>
